import React from 'react';
import { FaArrowDown } from 'react-icons/fa'; // You can use any icon you prefer
import './ScrollArrow.css'; // Import the CSS file for styling

const ScrollArrow = () => {
    // Scroll to the next section when the arrow is clicked
    const scrollToNextSection = () => {
        const nextSection = document.getElementById('next-section');
        if (nextSection) {
            nextSection.scrollIntoView({ behavior: 'smooth' });
        }
    };

    return (
        <div className="scroll-arrow" onClick={scrollToNextSection}>
            <FaArrowDown />
        </div>
    );
};

export default ScrollArrow;