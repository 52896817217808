/** @format */

import React, { useEffect, useState } from "react";

import Box from "@mui/material/Box";

import Button from "@mui/material/Button";

import Typography from "@mui/material/Typography";

import Modal from "@mui/material/Modal";

import { Grid, MenuItem, Select, TextField } from "@mui/material";

import BirthDatePicker from "../../common/BirthDatePicker";

import { useForm, Controller } from "react-hook-form";

import UploadFiles from "../../common/UploadFiles";

import Autocomplete from "@mui/material/Autocomplete";

import getConfigObject from "../../Util/Config";

import axios from "axios";

import { get } from "lodash";

import { showSuccessToast, showFailureToast, showInProgressToast } from "../../common/Toaster";

import {

  FileDescription,

  MaintenanceScheduleData,

} from "../../common/FileDescriptionData";

import FieldErrorText from "../../common/ErrorField";

import { styled } from "@mui/material/styles";

import DocTable from "../../common/DocTable";

import { isArray } from "lodash";

const Input = styled("input")({

  display: "none",

});

const style = {

  position: "absolute",

  top: "50%",

  left: "50%",

  transform: "translate(-50%, -50%)",

  width: "70%",

  height: "80%",

  bgcolor: "background.paper",

  boxShadow: 24,

  borderRadius: "8px",

  p: 4,

  display: "flex",

  flexDirection: "column",

  justifyContent: "space-between",

  overflow: "auto",

  margin: 2,

  "&::WebkitScrollbarTrack": {

    WebkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.3)",

    backgroundColor: "#fec90f",

  },

  "&::-webkit-scrollbar": {

    width: "4px",

    backgroundColor: "transparent",

  },

  "&::WebkitScrollbarThumb": {

    backgroundColor: "rgba(255,255,255,.04)",

  },

};



const defaultValues = {

  patientName: "",

  referralDate: "",

  dob: "",

  treatmentDate: "",

  treatmentStatus: "",

  referralId: "",

  referredName: "",

  mobile: "",

  insurance: "",

  patientId: "",

  comment: "test",

  desc: "",

  age: "",

  file: null,

  maintenanceSchedule: "",

  nextAppointment: null,

  caseClosed: false,

  files: [],

};



const treatmentStatusArr = ["Pending", "Treatment Completed", "In Treatment", "Treatment Dismissed"];



const AddDetails = (props) => {

  const { open, handleClose, patientInformation, handleSubmitData } = props;

  const [fileDescValue, SetFileDescValue] = useState("");

  const [fileAndDesc, setFileAndDesc] = useState([]);

  const [insuranceData, setinsuranceData] = useState([]);

  const {

    handleSubmit,

    control,

    reset,

    formState: { errors },

    setValue,

  } = useForm({

    defaultValues: defaultValues,

  });



  const onSubmitHandler = (data, e) => {

    e.preventDefault();

    e.stopPropagation();

    if (isArray(fileAndDesc)) {

      fileAndDesc.shift();

      data.fileAndDesc = fileAndDesc;

    }
    //console.log(data);
    handleSubmit(handleSubmitData.saveReferralDetails(data));

    handleClose();

  };



  const refferedPatient = () => {

    if (!patientInformation) {

      reset(defaultValues);

      return;

    }

    reset(patientInformation);

  };



  const uploadFiles = (event) => {

    const files = event.target.files;

    setValue("file", files);

    console.log("uploaded files are===>>>", files);

    prepareDataForTable(files);

  };



  const prepareDataForTable = (fileObj) => {

    let fileArr = [];

    for (let i = 0; i < fileObj.length; i++) {

      fileArr.push(fileObj[i]);

    }

    console.log("files array====>>>>>", fileArr);

    const tableHeader = ["Sr", "File Description", "File Name", "Delete"];

    let tableBody = fileArr.map((f, index) => ({

      sr: index + 1,

      desc: fileDescValue,

      fileName: f.name,

      action: "❌",

      file: f,

    }));

    let prevTabBody = fileAndDesc.slice(1);

    console.log("previous table body===>>> ", prevTabBody);

    console.log("table body", tableBody);



    setFileAndDesc([tableHeader, ...prevTabBody, ...tableBody]);

    console.log("after set state", fileAndDesc);

  };



  const onRemove = (fileName) => {

    console.log("on remove is called for...", fileName);

    let newFileList = fileAndDesc.filter((f) => f.fileName !== fileName);

    setFileAndDesc(newFileList);

  };



  const setDescription = (event) => {

    SetFileDescValue(event.target.value);

    console.log(event.target.value);

  };

  const fetchInsuranceData = async () => {

    try {

      const hospitalId = JSON.parse(localStorage.getItem("app:userInfo")).hospitalId;

      const insuranceConfig = getConfigObject("get", `user/getInsurance/${hospitalId}`);

      const insuranceResult = await axios(insuranceConfig);

      const insuranceRecords = get(insuranceResult, ["data", "data", "0"], {});

      const insuranceData = insuranceRecords.map((item, index) => ({
        label: item.provider,
        value: index // You can use a unique identifier here if needed, like item.providerId
      }));

      setinsuranceData(insuranceData);

      console.log(insuranceData)

    } catch (err) {

      showFailureToast("failed to fetch insurance record");

      console.error("failed to fetch insurance record", err);

    }

  };



  useEffect(() => {

    refferedPatient();

    fetchInsuranceData();

  }, []);



  return (

    <div>

      <Modal

        open={open}

        onClose={handleClose}

        aria-labelledby="modal-modal-title"

        aria-describedby="modal-modal-description"

      >

        <form onSubmit={handleSubmit(onSubmitHandler)}>

          <Box sx={style}>

            <Grid container spacing={2} alignItems="center">

              <Grid item xs={12} md={3}>

                <Typography variant="body2">Patient Name:</Typography>

              </Grid>

              <Grid item xs={12} md={3}>

                <Controller

                  control={control}

                  name="patientName"

                  defaultValue={"patientName"}

                  render={({ field: { onChange, value, onBlur } }) => (

                    <TextField

                      size="small"

                      fullWidth

                      disabled

                      name="patientName"

                      value={value}

                      onChange={onChange}

                    />

                  )}

                />

              </Grid>

              <Grid item xs={12} md={3}>

                <Typography variant="body2">Referred Date:</Typography>

              </Grid>

              <Grid item xs={12} md={3}>

                <Controller

                  control={control}

                  name="referralDate"

                  defaultValue="referralDate"

                  render={({ field: { onChange, value } }) => (

                    <BirthDatePicker

                      name="referralDate"

                      disabled

                      value={value}

                      onChange={onChange}

                    />

                  )}

                />

              </Grid>

              <Grid item xs={12} md={3}>

                <Typography variant="body2">Age:</Typography>

              </Grid>

              <Grid item xs={12} md={3}>

                <Controller

                  control={control}

                  name="age"

                  defaultValue={"age"}

                  render={({ field: { onChange, value, onBlur } }) => (

                    <TextField

                      size="small"

                      fullWidth

                      disabled

                      name="age"

                      value={value}

                      onChange={onChange}

                    />

                  )}

                />

              </Grid>

              <Grid item xs={12} md={3}>

                <Typography variant="body2">Referred By:</Typography>

              </Grid>

              <Grid item xs={12} md={3}>

                <Controller

                  control={control}

                  name="reffererName"

                  defaultValue="reffererName"

                  render={({ field: { onChange, value, onBlur } }) => (

                    <TextField

                      size="small"

                      fullWidth

                      disabled

                      name="reffererName"

                      value={value}

                      onChange={onChange}

                    />

                  )}

                />

              </Grid>

              <Grid item xs={12} md={3}>

                <Typography variant="body2">Date Of Birth:</Typography>

              </Grid>

              <Grid item xs={12} md={3}>

                <Controller

                  control={control}

                  name="dob"

                  defaultValue="dob"

                  render={({ field: { onChange, value } }) => (

                    <BirthDatePicker

                      name="dob"

                      disabled

                      value={value}

                      onChange={onChange}

                    />

                  )}

                />

              </Grid>

              <Grid item xs={12} md={3}>

                <Typography variant="body2">Treating Doctor:</Typography>

              </Grid>

              <Grid item xs={12} md={3}>

                <Controller

                  control={control}

                  name="referredName"

                  defaultValue="referredName"

                  render={({ field: { onChange, value, onBlur } }) => (

                    <TextField

                      size="small"

                      fullWidth

                      disabled

                      name="referredName"

                      value={value}

                      onChange={onChange}

                    />

                  )}

                />

              </Grid>

              <Grid item xs={12} md={3}>

                <Typography variant="body2">Primary Phone:</Typography>

              </Grid>

              <Grid item xs={12} md={3}>

                <Controller

                  control={control}

                  name="mobile"

                  defaultValue="mobile"

                  render={({ field: { onChange, value, onBlur } }) => (

                    <TextField

                      size="small"

                      fullWidth

                      disabled

                      name="mobile"

                      value={value}

                      onChange={onChange}

                    />

                  )}

                />

              </Grid>

              <Grid item xs={12} md={3}>

                <Typography variant="body2">Insurance Accepted:</Typography>

              </Grid>

              <Grid item xs={12} md={3}>

                <Controller

                  control={control}

                  name="insuranceProvider"

                  defaultValue=""

                  render={({ field: { onChange, value, onBlur } }) => (

                    <Autocomplete
                      options={insuranceData}
                      getOptionLabel={(option) => option.label || ""}
                      renderInput={(params) => <TextField {...params} />}
                      isOptionEqualToValue={(option, value) => option.value === value}
                      value={insuranceData.find(option => option.label === value) || null}
                      onChange={(e, newValue) => {
                        onChange(newValue ? newValue.label : null); // Send label instead of value
                      }}
                    />

                  )}

                />

              </Grid>

              <Grid item xs={12} md={3}>

                <Typography variant="body2">Secondary Phone:</Typography>

              </Grid>

              <Grid item xs={12} md={3}>

                <Controller

                  control={control}

                  name="mobile"

                  defaultValue={"mobile"}

                  render={({ field: { onChange, value, onBlur } }) => (

                    <TextField

                      size="small"

                      fullWidth

                      disabled

                      name="mobile"

                      value={value}

                      onChange={onChange}

                    />

                  )}

                />

              </Grid>

              <Grid item xs={12} md={3}>

                <Typography variant="body2">Treatment Date:</Typography>

              </Grid>

              <Grid item xs={12} md={3}>

                <Controller

                  control={control}

                  name="treatmentDate"

                  defaultValue="treatmentDate"

                  render={({ field: { onChange, value } }) => (

                    <BirthDatePicker

                      name="treatmentDate"

                      value={value}

                      onChange={onChange}

                    />

                  )}

                />

              </Grid>

              <Grid item xs={12} md={3}>

                <Typography variant="body2">Update:</Typography>

              </Grid>

              <Grid item xs={12} md={9}>

                <Controller

                  control={control}

                  name="comment"

                  defaultValue="comment"

                  rules={{

                    required: {

                      value: true,

                      message: `Please Fill the Treatment`,

                    },

                  }}

                  render={({ field: { onChange, value, onBlur } }) => (

                    <TextField

                      multiline

                      minRows={3}

                      fullWidth

                      name="comment"

                      value={value}

                      onChange={onChange}

                    />

                  )}

                />

                {errors?.comment?.message && (

                  <FieldErrorText errorMessage={errors?.comment?.message} />

                )}

              </Grid>

              {/*<Grid container spacing={2} alignItems="center">

              <Grid item xs={12} md={3}>

                <Typography variant="body2">Annual Appointment:</Typography>

              </Grid>

              <Grid item xs={12} md={3}>

                <Controller

                  control={control}

                  name="annualAppointment"

                  render={({ field: { onChange, value } }) => (

                    <BirthDatePicker

                      name="nextAppointment"

                      value={value}

                      onChange={onChange}

                    />

                  )}

                />

                {

                  errors?.nextAppointment?.message && (<FieldErrorText

                    errorMessage={errors?.nextAppointment?.message}

                  />)

                }

              </Grid>

              </Grid>*/}

            </Grid>

            <Grid container spacing={2} alignItems="center">

              <Grid item xs={12} md={3}>

                <Typography variant="body2">File Description:</Typography>

              </Grid>

              <Grid item xs={12} md={3}>

                <Controller

                  control={control}

                  name="desc"

                  render={({ field: { onChange, value, onBlur } }) => (

                    <Select

                      fullWidth

                      size="small"

                      value={fileDescValue}

                      onChange={setDescription}

                      name="desc"

                      isClearable={true}

                    >

                      {FileDescription.map((el, key) => (

                        <MenuItem key={key} value={el}>

                          {el}

                        </MenuItem>

                      ))}

                    </Select>

                  )}

                />

              </Grid>

              <Grid item xs={12} md={3}>

                <Typography variant="body2">File Upload:</Typography>

              </Grid>

              <Grid item xs={12} md={3}>

                <label

                  htmlFor="contained-button-file"

                  hidden={fileDescValue === ""}

                >

                  <Input

                    id="contained-button-file"

                    multiple

                    type="file"

                    onChange={uploadFiles}

                  />

                  <Button variant="contained" component="span">

                    Upload Files

                  </Button>

                </label>

                {/* <input

                  type="file"

                  class="form-control"

                  multiple

                  name="upload-file"

                  onChange={uploadFiles}

                /> */}

                {/* <UploadFiles  description={fileDescValue}/> */}

              </Grid>

            </Grid>



            <Grid

              container

              spacing={2}

              alignItems="stretch"

              textAlign="center"

              justifyContent="center"

            >

              <Grid item xs={12} md={8}>

                {fileAndDesc.length > 1 && (

                  <DocTable data={fileAndDesc} handleRemove={onRemove} />

                )}

              </Grid>

            </Grid>

            <Grid container spacing={2} alignItems="center">

              <Grid item xs={12} md={3}>

                <Typography variant="body2">Maintenance Schedule:</Typography>

              </Grid>

              <Grid item xs={12} md={3}>

                <Controller

                  control={control}

                  name="maintenanceSchedule"

                  rules={{

                    required: {

                      value: false,

                      message: `Please Select Maintenance Schedule`,

                    },

                  }}

                  render={({ field: { onChange, value, onBlur } }) => (

                    <Select

                      fullWidth

                      size="small"

                      value={value}

                      onChange={onChange}

                      name="maintenanceSchedule"

                      isClearable={true}

                    >

                      {MaintenanceScheduleData.map((el, key) => (

                        <MenuItem key={key} value={el}>

                          {el}

                        </MenuItem>

                      ))}

                    </Select>

                  )}

                />

                {errors?.maintenanceSchedule?.message && (

                  <FieldErrorText

                    errorMessage={errors?.maintenanceSchedule?.message}

                  />

                )}

              </Grid>

              <Grid item xs={12} md={3}>

                <Typography variant="body2">Next Appointment:</Typography>

              </Grid>

              <Grid item xs={12} md={3}>

                <Controller

                  control={control}

                  name="nextAppointment"

                  rules={{

                    required: {

                      value: false,

                      message: `Please enter next appointment`,

                    },

                  }}

                  render={({ field: { onChange, value } }) => (

                    <BirthDatePicker

                      name="nextAppointment"

                      value={value}

                      onChange={onChange}

                    />

                  )}

                />

                {errors?.nextAppointment?.message && (

                  <FieldErrorText

                    errorMessage={errors?.nextAppointment?.message}

                  />

                )}

              </Grid>

              <Grid item xs={12} md={3}>

                <Typography variant="body2">Treatment Status:</Typography>

              </Grid>

              <Grid item xs={12} md={3}>

                <Controller

                  control={control}

                  name="treatmentStatus"

                  defaultValue="treatmentStatus"

                  render={({ field: { onChange, value, onBlur } }) => (

                    <Select

                      fullWidth

                      size="small"

                      value={value}

                      onChange={onChange}

                      name="treatmentStatus"

                      isClearable={true}

                    >

                      {treatmentStatusArr.map((el, key) => (

                        <MenuItem key={key} value={el}>

                          {el}

                        </MenuItem>

                      ))}

                    </Select>

                  )}

                />

              </Grid>

            </Grid>



            {/*

            <Grid container spacing={2} alignItems="center">

              <Grid item xs={12} md={3}>

                <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: 'center' }}>

                  <Typography variant="body2">Close Referral:</Typography>

                  <Controller

                    control={control}

                    name="caseClosed"

                    defaultValue={""}

                    render={({ field: { onChange, value, onBlur } }) => (

                      <Checkbox

                        label="Case Closed"

                        onChange={onChange}

                        checked={value}

                        name="caseClosed"

                      />

                    )}

                  />

                </Box>

              </Grid>

            </Grid>

                    */}



            <Grid

              container

              spacing={2}

              alignItems="center"

              textAlign="center"

              justifyContent="center"

            >

              <Grid item xs={12} md={4}>

                <Button variant="contained" fullWidth type="submit">

                  Submit

                </Button>

              </Grid>

            </Grid>

          </Box>

        </form>

      </Modal>

    </div>

  );

};



export default AddDetails;

